import React, { useState } from "react";
import ProfileTopNavBar from "../DashBaord/Top_navbar";
import {
  Collapse,
  Typography,
  notification,
  Upload,
  Switch,
  TimePicker,
} from "antd";
import { CheckCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import { updateProfile } from "../../controllers/accounts/Account";
import { useLocation, useNavigate } from "react-router-dom";
import { beforeUpload } from "../../controllers/common";

function StoreDetailsForm() {
  const location = useLocation();
  const [VENDORINFO, setVendorInfo] = useState(location.state.VENDORINFO || {});
  const [image, setImageList] = useState("");
  const navigate = useNavigate();
  const [errors, serError] = useState([]);

  /*********************************************************
   *  This function is use to handle success notification show
   *********************************************************/
  const handleSuccessMessg = () => {
    notification.open({
      message: "Success!",
      description: " Data Saves Successfully .",
      placement: "topRight",
      duration: 2,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  }; //End

  /*********************************************************
   *  This function is use to handle error notification show
   *********************************************************/
  const handleErrorMsg = (msg = "") => {
    notification.open({
      message: "Opps!",
      // description: error?.formError || msg,
      placement: "topRight",
      duration: 2,
      icon: <CloseOutlined style={{ color: "red" }} />,
    });
  }; //End

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      setVendorInfo((pre) => ({
        ...pre,
        [name]: value,
      }));
    } catch (error) {
      console.log("Vendor StoreDetails Error", error);
    }
  };

  /*********************************************************
   *  This function is use to handle image object
   *********************************************************/
  const handleImageChnage = (e) => {
    setImageList(e.fileList[0].originFileObj);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let form = new FormData(e.target);
    form.append("admin_image", image ? image : null);
    // console.log(form.get('admin_last_name'));
    form.append("editId", VENDORINFO?._id);
    form.append("ipAddress", sessionStorage.getItem("IP_ADDRESS"));
    const isValidate = validateFormData(form);
    console.log("form validation", isValidate);
    console.log("err", errors);
    if (isValidate) {
      const res = await updateProfile(form);
      console.log("Vendor updateProfile", res);
      if (res.status === true) {
        setImageList("");
        handleSuccessMessg();
        // handleCancel();
        navigate("/profile");
      } else {
        // StorageError((pre) => ({
        //   ...pre,
        //   formError: res?.message
        // }))
        handleErrorMsg();
      }
      // setIsLoading(false);
    } else {
      // setIsLoading(false);
      handleErrorMsg("Please fill required fields.");
    }
  }; //End

  /*********************************************************
   *  This function is use to validate form data before submit
   *********************************************************/
  const validateFormData = (formData) => {
    if (
      formData.get("vendor_first_name") === "undefined" ||
      formData.get("vendor_first_name") === null ||
      formData.get("vendor_first_name").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        vendor_first_name: "vendor first name is required",
      }));
      return false;
    } else if (
      formData.get("vendor_last_name") === "undefined" ||
      formData.get("vendor_last_name") === null ||
      formData.get("vendor_last_name").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        vendor_last_name: "vendor last name is required",
      }));
      return false;
    } else if (
      formData.get("vendor_phone") === "undefined" ||
      formData.get("vendor_phone") === null ||
      formData.get("vendor_phone").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        vendor_phone: "vendor_phone is required",
      }));
      return false;
    } else if (
      formData.get("vendor_email") === "undefined" ||
      formData.get("vendor_email") === null ||
      formData.get("vendor_email").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        vendor_email: "vendor_email is required",
      }));
      return false;
    } else if (
      formData.get("vendor_address") === "undefined" ||
      formData.get("vendor_address") === null ||
      formData.get("vendor_address").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        vendor_address: "vendor_address is required",
      }));
      return false;
    } else if (
      formData.get("vendor_city") === "undefined" ||
      formData.get("vendor_city") === null ||
      formData.get("vendor_city").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        vendor_city: "vendor_city is required",
      }));
      return false;
    } else if (
      formData.get("landmark") === "undefined" ||
      formData.get("landmark") === null ||
      formData.get("landmark").trim() === ""
    ) {
      serError((preError) => ({
        ...preError,
        landmark: "landmark is required",
      }));
      return false;
    } else {
      return true;
    }
  };
  console.log("VENDORINFO-------", VENDORINFO);

  return (
    <div>
      <ProfileTopNavBar></ProfileTopNavBar>
      <div className="container-fluid">
        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form onSubmit={handleSubmit}>
                <div class="py-2 text-start">
                  <span className="profile_store_details_heading">
                    Profile Details
                  </span>
                </div>
                <div className="store_details_all_form_section">
                  <div class="form-row">
                    <div className="store_details_form_owner_name">
                      <div class="col">
                        <label
                          for="firstName"
                          className="profile_label_store_name"
                        >
                          First Name
                        </label>
                        <input
                          className="form-control all_input_tag_tagenter_product_"
                          type="text"
                          name="vendor_first_name"
                          placeholder="First Name"
                          id="vendor_first_name"
                          value={VENDORINFO?.vendor_first_name}
                          onChange={handleChange}
                        />
                        {errors?.vendor_first_name ? (
                          <p style={{ color: "red" }}>
                            {errors.vendor_first_name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="col">
                        <label
                          for="lastName"
                          className="profile_label_store_name"
                        >
                          Last Name
                        </label>
                        <input
                          className="form-control all_input_tag_tagenter_product_"
                          type="text"
                          name="vendor_last_name"
                          placeholder="last Name"
                          id="vendor_last_name"
                          value={VENDORINFO?.vendor_last_name}
                          onChange={handleChange}
                        />
                        {errors?.vendor_last_name ? (
                          <p style={{ color: "red" }}>
                            {errors.vendor_last_name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="col">
                        <label
                          for="phoneNo"
                          className="profile_label_store_name"
                        >
                          Phone No.
                        </label>
                        <input
                          className="form-control all_input_tag_tagenter_product_"
                          type="number"
                          name="vendor_phone"
                          placeholder="+91"
                          id="phoneNo"
                          value={VENDORINFO?.vendor_phone}
                          onChange={handleChange}
                        />
                        {errors?.vendor_phone ? (
                          <p style={{ color: "red" }}>{errors.vendor_phone}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form-row col-12">
                    <div className="store_details_form_owner_name">
                      <div class="col-4">
                        <label
                          for="emailAddress"
                          className="profile_label_store_name"
                        >
                          Email Address*
                        </label>
                        <input
                          className="form-control all_input_tag_tagenter_product_"
                          type="email"
                          name="vendor_email"
                          placeholder="Email Address"
                          id="vendor_email"
                          value={VENDORINFO?.vendor_email}
                          onChange={handleChange}
                        />
                        {errors?.vendor_email ? (
                          <p style={{ color: "red" }}>{errors.vendor_email}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="col-8">
                        <label
                          for="ownerName"
                          className="profile_label_store_name"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control all_input_tag_tagenter_product_"
                          name="vendor_address"
                          placeholder="Address"
                          id="vendor_address"
                          value={VENDORINFO?.vendor_address}
                          onChange={handleChange}
                        />
                        {errors?.vendor_last_name ? (
                          <p style={{ color: "red" }}>
                            {errors.vendor_last_name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div className="store_details_form_owner_name">
                      <div class="col">
                        <label for="city" className="profile_label_store_name">
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control all_input_tag_tagenter_product_"
                          name="vendor_city"
                          placeholder="City"
                          id="vendor_city"
                          value={VENDORINFO?.vendor_city}
                          onChange={handleChange}
                        />
                      </div>
                      <div class="col">
                        <label
                          for="landMark"
                          className="profile_label_store_name"
                        >
                          Landmark
                        </label>
                        <input
                          type="text"
                          class="form-control all_input_tag_tagenter_product_"
                          id="landmark"
                          name="landmark"
                          placeholder="Landmark"
                          value={VENDORINFO?.vendor_street}
                          onChange={handleChange}
                        />
                        {errors?.landmark ? (
                          <p style={{ color: "red" }}>{errors.landmark}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* Image */}
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label className="profile_label_store_name all_top_and_bottom_for_input_text">Image</label>
                      <div className="inventory_upload_file_main_div">
                        <Upload
                          onChange={handleImageChnage}
                          beforeUpload={beforeUpload}
                          name="image"
                          maxCount="1"
                          listType="picture-card"
                          className="product_inventory_edit_upload"
                        >
                          <button
                            className="inventroy_edit_page_antd_uplaod_button"
                            type="button"
                          >
                            <div className="inventroy_edit_page_antd_choose_file">
                              Choose File
                            </div>
                          </button>
                        </Upload>
                      </div>
                      <p style={{ color: "blue" }} className="all_top_and_bottom_for_input_text">
                        *Note: Please choose an image of JPEG/JPG/PNG format
                        file.
                      </p>
                      {errors?.image ? <p style={{ color: "red" }}>{errors.image}</p> : ""}
                    </div>
                  </div>
                </div>

                {/* Store Timing
          <div className="profile_store_timing_main_div">
            <div>
              <span className="profile_label_store_name">Store Timings</span>
            </div>
            <div class="card store_details_monday">
              <div class="card-body store_details_all_collapse">
                <Collapse
                  expandIcon={customExpandIcon}
                  expandIconPosition="right"
                  defaultActiveKey={["1"]}
                  className="store_details_collapse"
                >
                  <Panel
                    className="store_switch_main_div"
                    header={
                      <div className="switch_panel_header">
                        <Switch defaultChecked className="categories_switch_" />
                        <span className="store_profile_weekly_name">
                          Monday
                        </span>
                      </div>
                    }
                    key="1"
                  >
                    <Text>
                      <div className="store_time_picker">
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                        <span className="store_details_to">to</span>
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                      </div>
                    </Text>
                  </Panel>
                </Collapse>
                <Collapse expandIconPosition="right"  className="store_details_collapse">
                  <Panel
                    className="store_switch_main_div"
                    header={
                      <div className="switch_panel_header">
                        <Switch className="categories_switch_" />
                        <span className="store_profile_weekly_name">
                          Tuesday
                        </span>
                      </div>
                    }
                    key="2"
                  >
                    <Text>
                      <div className="store_time_picker">
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                        <span className="store_details_to">to</span>
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                      </div>
                    </Text>
                  </Panel>
                </Collapse>
                <Collapse expandIconPosition="right"  className="store_details_collapse">
                  <Panel
                    className="store_switch_main_div"
                    header={
                      <div className="switch_panel_header">
                        <Switch className="categories_switch_" />
                        <span className="store_profile_weekly_name">
                          Wednesday
                        </span>
                      </div>
                    }
                    key="3"
                  >
                    <Text>
                      <div className="store_time_picker">
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                        <span className="store_details_to">to</span>
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                      </div>
                    </Text>
                  </Panel>
                </Collapse>
                <Collapse expandIconPosition="right"  className="store_details_collapse">
                  <Panel
                    className="store_switch_main_div"
                    header={
                      <div className="switch_panel_header">
                        <Switch className="categories_switch_" />
                        <span className="store_profile_weekly_name">
                          Thursday
                        </span>
                      </div>
                    }
                    key="4"
                  >
                    <Text>
                      <div className="store_time_picker">
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                        <span className="store_details_to">to</span>
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                      </div>
                    </Text>
                  </Panel>
                </Collapse>
                <Collapse expandIconPosition="right"  className="store_details_collapse">
                  <Panel
                    className="store_switch_main_div"
                    header={
                      <div className="switch_panel_header">
                        <Switch className="categories_switch_" />
                        <span className="store_profile_weekly_name">
                          Friday
                        </span>
                      </div>
                    }
                    key="5"
                  >
                    <Text>
                      <div className="store_time_picker">
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                        <span className="store_details_to">to</span>
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                      </div>
                    </Text>
                  </Panel>
                </Collapse>
                <Collapse expandIconPosition="right"  className="store_details_collapse"> 
                  <Panel
                    className="store_switch_main_div"
                    header={
                      <div className="switch_panel_header">
                        <Switch className="categories_switch_" />
                        <span className="store_profile_weekly_name">
                          Saturday
                        </span>
                      </div>
                    }
                    key="6"
                  >
                    <Text>
                      <div className="store_time_picker">
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                        <span className="store_details_to">to</span>
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                      </div>
                    </Text>
                  </Panel>
                </Collapse>
                <Collapse expandIconPosition="right"  className="store_details_collapse">
                  <Panel
                    className="store_switch_main_div"
                    header={
                      <div className="switch_panel_header">
                        <Switch className="categories_switch_" />
                        <span className="store_profile_weekly_name">
                          Sunday
                        </span>
                      </div>
                    }
                    key="7"
                  >
                    <Text>
                      <div className="store_time_picker">
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                        <span className="store_details_to">to</span>
                        <div className="store_deatils_time_picker_contnet_section">
                          <TimePicker
                            defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                            size="large"
                            className="store_time_picker_main_section"
                          />
                        </div>
                      </div>
                    </Text>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
          Bank Details
          <div class="py-5 text-start">
            <span className="profile_store_details_heading">Bank Details</span>
          </div>
          <div className="store_details_all_form_section">
            <div class="form-row">
              <div className="store_details_form_owner_name">
                <div class="col">
                  <label for="bankName" className="profile_label_store_name">
                    Bank Name
                  </label>
                  <input
                    type="text"
                    class="form-control store_input_field"
                    // placeholder="Adidas Originals"
                    id="bankName"
                  />
                </div>
                <div class="col">
                  <label for="accountType" className="profile_label_store_name">
                    Account Type
                  </label>
                  <select
                    id="accountType"
                    class="form-select store_input_field"
                    aria-label="Default select example"
                  >
                    <option selected>Select</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div class="col">
                  <label
                    for="phoneNoBankDetails"
                    className="profile_label_store_name"
                  >
                    Phone No.
                  </label>
                  <input
                    type="number"
                    class="form-control store_input_field"
                    placeholder="+91"
                    id="phoneNoBankDetails"
                  />
                </div>
              </div>
            </div>
            <div class="form-row ">
              <div className="store_details_form_owner_name">
                <div class="col">
                  <label
                    for="bankAccountNumber"
                    className="profile_label_store_name"
                  >
                    Account Number
                  </label>
                  <input
                    type="number"
                    class="form-control store_input_field"
                    // placeholder="Adidas Originals"
                    id="bankAccountNumber"
                  />
                </div>
                <div class="col">
                  <label
                    for="reEnterAccountNumber"
                    className="profile_label_store_name"
                  >
                    Re-enter Account Number
                  </label>
                  <input
                    type="number"
                    class="form-control store_input_field"
                    // placeholder="Adidas Originals"
                    id="reEnterAccountNumber"
                  />
                </div>
                <div class="col">
                  <label for="ifscCode" className="profile_label_store_name">
                    IFSC Code
                  </label>
                  <input
                    type="text"
                    class="form-control store_input_field"
                    // placeholder="+91"
                    id="ifscCode"
                  />
                </div>
              </div>
            </div>
          </div> */}
                <div className="store_details_form_submit_button">
                  <button
                    type="submit"
                    class="btn btn-dark store_details_form_btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreDetailsForm;
