import { useEffect } from "react";
import { useUserStore } from "../../lib/userStore.js";
import { useChatStore } from "../../lib/chatStore";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebaseConfig.js";
import TopNavbar from "./../DashBaord/Top_navbar.js";
import Chat from "../../components/message/chat/Chat.jsx";
import List from "../../components/message/list/List.jsx";

const Message = () => {
  const { currentUser, isLoading, fetchUserInfo } = useUserStore();
  const { chatId, resetChat } = useChatStore();

  useEffect(() => {
    const unSub = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserInfo(user.uid);
      } else {
        console.log("No authenticated user found.");
        fetchUserInfo(null);
      }
    });

    // Reset chat when component mounts
    resetChat();

    return () => {
      unSub();
    };
  }, [fetchUserInfo, resetChat]);

  if (isLoading) return <div>Loading...</div>;
  if (!currentUser) return <div>Unauthorized</div>;

  return (
    <div className="container ">
      <div className="notification_top_navbar_for_frisbeee">
        <TopNavbar title={`Messages`} />
        <div className="message_section_all_div_div">
          {/* <div className="message_section_all_list_item_first">
            <List />
          </div> */}
          <div className="message_section_all_list_item_second">
            <Chat />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
