import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Flex, notification, Upload } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../../../components/Loading/LoadingEffect";
import { createPet } from "../../../controllers/services/dogAdoption";
import moment from "moment";
import { ucfirst, commonList } from "../../../controllers/common";
import {
  breedList,
  chnageStatus,
} from "../../../controllers/services/dogAdoption";
import { beforeUpload } from "../../../controllers/common";

const AdoptionsAddEditPopUp = ({
  promotionsAddEditPopUpmodal,
  promotionaToggleAddEditPopUp,
  addEditData,
  setAddEditData,
}) => {
  const handleCancel = () => {
    setAddEditData("");
    setImageList("");
    promotionaToggleAddEditPopUp();
  };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  /************************************ */
  const [breeds, setBreeds] = useState([]); // State for breeds
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  // const [ALLVENDOR, setAllVendor] = useState([]);
  const [ALLSTORE, setAllStore] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [ALLSERVICES, setAllServices] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [imageList, setImageList] = useState("");

  const getCommonListData = async () => {
    // const vendorId =
    try {
      setLoading(true);
      setAllStore([]);
      setAllBranch([]);
      const options = {
        list: ["service_category", "store", "branch"],
        serviceCat: { select: { name: true } },
        serviceSubCat: { select: { category: true, name: true } },
        store: { select: { shop_name: true } },
        branch: { select: { storeData: true, branch_name: true } },
      };
      const res = await commonList(options);
      if (res?.status === true) {
        setAllStore(res?.result?.store);
        setAllBranch(res?.result?.branch);

        if (addEditData?.branch) {
          const filterArray = res?.result?.branch.filter(
            (val) => val.storeData === addEditData?.store
          );
          setFilterBranch(filterArray);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getServiceListData = async (vendorId) => {
    try {
      setLoading(true);
      setAllStore([]);
      setAllBranch([]);
      const options = {
        vendorId: vendorId,
        list: ["service_category"],
        serviceCat: { select: { name: true } },
        serviceSubCat: { select: { category: true, name: true } },
      };

      const res = await commonList(options);
      if (res?.status === true) {
        setAllServices(res?.result?.serviceCat);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChnage = (e) => {
    try {
      const { name, value } = e.target;
      if (name === "promocode") {
        const pattern = /[^a-zA-Z0-9\s]/g;
        const cleanText = value.replace(pattern, "");
        setAddEditData((pre) => ({
          ...pre,
          [name]: cleanText.toUpperCase(),
        }));

        setErrors((preError) => ({
          ...preError,
          [name]: "",
        }));
      } else if (name === "vendor") {
        setAddEditData((pre) => ({
          ...pre,
          [name]: value,
        }));

        setErrors((preError) => ({
          ...preError,
          [name]: "",
        }));
        getCommonListData(value);
        setFilterBranch([]);
      } else if (name === "store") {
        setAddEditData((pre) => ({
          ...pre,
          [name]: value,
        }));

        setErrors((preError) => ({
          ...preError,
          [name]: "",
        }));
        const filterArray = ALLBRANCH.filter((val) => val.storeData === value);
        setFilterBranch(filterArray);
      } else {
        setAddEditData((pre) => ({
          ...pre,
          [name]: value,
        }));

        setErrors((preError) => ({
          ...preError,
          [name]: "",
        }));
      }
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const form = new FormData(e.target);
      form.append("image", imageList ? imageList : null);
      form.append("ipAddress", sessionStorage.getItem("IP_ADDRESS"));
      form.append(
        "redeem_to",
        addEditData?.redeem_to ? addEditData.redeem_to : "all"
      );
      form.append(
        "discount_type",
        addEditData?.discount_type ? addEditData.discount_type : "AED"
      );
      const isValidate = await validateFormData(form);

      if (isValidate) {
        // const formDataObject = {};
        // form.forEach((value, key) => {
        //   formDataObject[key] = value;
        // });
        const res = await createPet(form);
        // console.log("addeditdata res", res)
        if (res.status === true) {
          setLoading(false);
          notification.open({
            message: "Success",
            description: " Data save Successfully .",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          handleCancel();
        } else {
          setLoading(false);
          notification.open({
            message: "Error",
            description: res?.message || "Please try after some time",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
        }
      } else {
        setLoading(false);
        notification.open({
          message: "Oops!",
          description: " Please fill required fields.",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validateFormData = async (formData) => {
    try {
      if (
        formData.get("store") === "undefined" ||
        formData.get("store") === null ||
        formData.get("store").trim() === ""
      ) {
        setErrors((preError) => ({
          ...preError,
          store: "Store is required",
        }));
        return false;
      }else if (
        formData.get("branch") === "undefined" ||
        formData.get("branch") === null ||
        formData.get("branch").trim() === ""
      ) {
        setErrors((preError) => ({
          ...preError,
          branch: "Branch is required",
        }));
        return false;
      }else if (
        formData.get("pet_name") === "undefined" ||
        formData.get("pet_name") === null ||
        formData.get("pet_name").trim() === ""
      ) {
        setErrors((preError) => ({
          ...preError,
          pet_name: "Pet name is required",
        }));
        return false;
      } else if (
        formData.get("description") === "undefined" ||
        formData.get("description") === null ||
        formData.get("description").trim() === ""
      ) {
        setErrors((preError) => ({
          ...preError,
          description: "Description is required.",
        }));
        return false;
      } else if (
        formData.get("about") === "undefined" ||
        formData.get("about") === null ||
        formData.get("about").trim() === ""
      ) {
        setErrors((preError) => ({
          ...preError,
          about: "About is required.",
        }));
        return false;
      } else if (
        formData.get("breed") === "undefined" ||
        formData.get("breed") === null ||
        formData.get("breed").trim() === ""
      ) {
        setErrors((preError) => ({
          ...preError,
          breed: "Breed is required",
        }));
        return false;
      } else if (
        formData.get("gender") === "undefined" ||
        formData.get("gender") === null ||
        formData.get("gender").trim() === ""
      ) {
        setErrors((preError) => ({
          ...preError,
          gender: "Gender is required",
        }));
        return false;
      } else if (
        formData.get("mobile_no") === "undefined" ||
        formData.get("mobile_no") === null ||
        formData.get("mobile_no").trim() === ""
      ) {
        setErrors((preError) => ({
          ...preError,
          mobile_no: "Numberis required",
        }));
        return false;
      } else if (formData.get("redeem_to")) {
        const redeem_to = formData.get("redeem_to");
        if (redeem_to === "store") {
          if (
            formData.get("vendor") === "undefined" ||
            formData.get("vendor") === null ||
            formData.get("vendor").trim() === ""
          ) {
            setErrors((preError) => ({
              ...preError,
              vendor: "Please select vendor.",
            }));
            return false;
          } else if (
            formData.get("store") === "undefined" ||
            formData.get("store") === null ||
            formData.get("store").trim() === ""
          ) {
            setErrors((preError) => ({
              ...preError,
              store: "Please select store.",
            }));
            return false;
          } else {
            return true;
          }
        } else if (redeem_to === "branch") {
             if (
            formData.get("store") === "undefined" ||
            formData.get("store") === null ||
            formData.get("store").trim() === ""
          ) {
            setErrors((preError) => ({
              ...preError,
              store: "Please select store.",
            }));
            return false;
          } else if (
            formData.get("branch") === "undefined" ||
            formData.get("branch") === null ||
            formData.get("branch").trim() === ""
          ) {
            setErrors((preError) => ({
              ...preError,
              branch: "Please select branch.",
            }));
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const handleTypeClick = (name) => {
    try {
      setAddEditData((pre) => ({
        ...pre,
        redeem_to: name,
      }));
    } catch (error) {}
  };

  const handleDiscountTypeClick = (name) => {
    try {
      setAddEditData((pre) => ({
        ...pre,
        discount_type: name,
      }));
    } catch (error) {}
  };

  // Function to fetch breeds
  const getList = async () => {
   
    try {
      const options = {
        condition: {},
        select: { name: 1 },
        sort: { _id: -1 },
      };
      const listData = await breedList(options);
      console.log("listData111:", listData); // Log the entire response to inspect it
      if (listData.status && Array.isArray(listData.result)) {
        setBreeds(listData.result.map((breed) => breed.name)); // Assuming each breed object has a 'name' field
      } else {
        setBreeds([]);
      }
    } catch (error) {
      console.error("Error fetching breeds:", error);
      setBreeds([]);
    }
  };

  useEffect(() => {
    getList();
  }, []);
  const handleChange = (e) => {
    setAddEditData({
      ...addEditData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getList();
    getServiceListData();
    setLoading(true);
    getCommonListData();
  }, [promotionsAddEditPopUpmodal]);

  //   /*********************************************************
  //   *  This function is use to handle image object
  //   *********************************************************/
  const handleImageChange = (e) => {
    setImageList(e.fileList[0].originFileObj);
  };

  return (
    <Dialog
      open={promotionsAddEditPopUpmodal}
      onClose={promotionaToggleAddEditPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Request Adoption
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form
                  className="categories_open_popup_form"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="editId"
                    id="editId"
                    value={addEditData?._id}
                  />
                  <div className="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        for="store"
                        className="all_lable_for_vendor_dashbaord "
                      >
                        {" "}
                        <span style={{ color: "red" }}>*</span>Store{" "}
                      </label>
                      <select
                        id="store"
                        name="store"
                        onChange={handleChnage}
                        class="form-control store_input_field_field"
                      >
                        <option value="">Select a store</option>
                        {ALLSTORE &&
                          ALLSTORE.map((items, index) => (
                            <option
                              key={index}
                              value={items._id}
                              selected={
                                addEditData?.store === items._id ? true : false
                              }
                            >
                              {`${ucfirst(items.shop_name)}`}
                            </option>
                          ))}
                      </select>
                      {errors?.store ? (
                        <p style={{ color: "red" }}>{errors.store}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        for="branch"
                        className="all_lable_for_vendor_dashbaord"
                      >
                        {" "}
                        <span style={{ color: "red" }}>*</span> Branch{" "}
                      </label>
                      <select
                        id="branch"
                        name="branch"
                        onChange={handleChnage}
                        class="form-control store_input_field_field"
                      >
                        <option value="">Select a branch</option>
                        {filterBranch &&
                          filterBranch.map((items, index) => (
                            <option
                              key={index}
                              value={items._id}
                              selected={
                                addEditData?.branch === items._id ? true : false
                              }
                            >
                              {`${ucfirst(items.branch_name)}`}
                            </option>
                          ))}
                      </select>
                      {errors?.branch ? (
                        <p style={{ color: "red" }}>{errors.branch}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="name"
                        className="all_lable_for_vendor_dashbaord"
                      >
                        <span style={{ color: "red" }}>*</span>Pet Name
                      </label>
                      <input
                        type="text"
                        class="form-control store_input_field_field"
                        id="pet_name"
                        placeholder="Enter Pet Name"
                        name="pet_name"
                        value={addEditData?.pet_name}
                        onChange={handleChnage}
                      />
                      {errors?.pet_name ? (
                        <p style={{ color: "red" }}>{errors.pet_name}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="name"
                        className="all_lable_for_vendor_dashbaord"
                      >
                        <span style={{ color: "red" }}>*</span>Description
                      </label>
                      <input
                        type="text"
                        class="form-control store_input_field_field"
                        id="description"
                        placeholder="Enter Description"
                        name="description"
                        value={addEditData?.description}
                        onChange={handleChnage}
                      />
                      {errors?.description ? (
                        <p style={{ color: "red" }}>{errors.description}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="about"
                        className="all_lable_for_vendor_dashbaord"
                      >
                       <span style={{ color: "red" }}>*</span> About
                      </label>
                      <textarea
                        class="form-control store_input_field_field"
                        id="about"
                        placeholder="Enter about the dog"
                        name="about"
                        onChange={handleChnage}
                      >
                        {addEditData?.about}
                      </textarea>
                      {errors?.about ? (
                        <p style={{ color: "red" }}>{errors.about}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="breed"
                        className="categories_open_popup_label_name"
                      >
                       <span style={{ color: "red" }}>*</span> Breed
                      </label>
                      <select
                        className="form-control"
                        id="breed"
                        name="breed"
                        value={addEditData?.breed || ""}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select breed
                        </option>
                        {Array.isArray(breeds) &&
                          breeds.map((breed, index) => (
                            <option key={index} value={breed}>
                              {breed}
                            </option>
                          ))}
                      </select>
                      {errors?.breed && (
                        <p style={{ color: "red" }}>{errors?.breed}</p>
                      )}
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="name"
                        className="all_lable_for_vendor_dashbaord"
                      >
                        <span style={{ color: "red" }}>*</span>Gender
                      </label>
                      <input
                        type="text"
                        class="form-control store_input_field_field"
                        id="gender"
                        placeholder="Enter Gender"
                        name="gender"
                        value={addEditData?.gender}
                        onChange={handleChnage}
                      />
                      {errors?.gender ? (
                        <p style={{ color: "red" }}>{errors.gender}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label
                        htmlFor="name"
                        className="all_lable_for_vendor_dashbaord"
                      >
                        <span style={{ color: "red" }}>*</span>Mobile Number
                      </label>
                      <input
                        type="number"
                        class="form-control store_input_field_field"
                        id="mobile_no"
                        placeholder="Enter mobile number"
                        name="mobile_no"
                        value={addEditData?.mobile_no}
                        onChange={handleChnage}
                      />
                      {errors?.mobile_no ? (
                        <p style={{ color: "red" }}>{errors.mobile_no}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label className="all_lable_for_vendor_dashbaord">
                        Image
                      </label>
                      <div className="inventory_upload_file_main_div">
                        <Upload
                          onChange={handleImageChange}
                          name="image"
                          maxCount="1"
                          listType="picture-card"
                          className="product_inventory_edit_upload"
                          beforeUpload={beforeUpload}
                        >
                          <button
                            className="inventroy_edit_page_antd_uplaod_button"
                            type="button"
                          >
                            <div className="inventroy_edit_page_antd_choose_file">
                              Choose File
                            </div>
                          </button>
                        </Upload>
                      </div>
                      <p style={{ color: "blue" }} className="all_top_and_bottom_for_input_text">
                    *Note: Please choose an image of JPEG/JPG/PNG format file.
                  </p>
                      {errors?.image ? (
                        <p style={{ color: "red" }}>{errors.image}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="inventory_open_popup_action_btn">
                      <button
                        type="button"
                        class="btn btn-light categories_cancel_button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button type="Submit" class="btn btn-danger ">
                        {addEditData?._id ? "Save Chnage" : "Create"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
        <Loading isLoading={loading} />
      </div>
    </Dialog>
  );
};

export default AdoptionsAddEditPopUp;
