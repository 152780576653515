import { doc, getDoc } from "firebase/firestore";
import create from "zustand";
import { db } from "../config/firebaseConfig";

export const useUserStore = create((set) => ({
  currentUser: null,
  isLoading: true,
  fetchUserInfo: async (uid) => {
    if (!uid) return set({ currentUser: null, isLoading: false });

    try {
      console.log("Fetching user info for UID:", uid);
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("User data found:", docSnap.data());
        set({ currentUser: { ...docSnap.data(), id: uid }, isLoading: false });
      } else {
        console.log("No user document found for UID:", uid);
        set({ currentUser: null, isLoading: false });
      }
    } catch (error) {
      console.log("Error fetching user info:", error);
      set({ currentUser: null, isLoading: false });
    }
  },
}));
